import React from 'react'

const PrivacyPolicyBangla = () => {
    return (
        <>
            <div className='title tac'>Sellkon.com-এর গোপনীয়তা নীতি</div>
            <div className='txt'>
                শেষ আপডেট: মে ৫, ২০২৪
            </div>
            <div className='txt'>
                Sellkon.com ("Sellkon", “সেলকন”, "আমরা", বা "আমাদের") আপনার ব্যক্তিগত তথ্যের গোপনীয়তা এবং নিরাপত্তা রক্ষা করতে প্রতিশ্রুতিবদ্ধ। এই গোপনীয়তা নীতি বর্ণনা করে যে আপনি যখন আমাদের ওয়েবসাইট, Sellkon.com, এবং যেকোন সম্পর্কিত পরিষেবাগুলি (সম্মিলিতভাবে, "পরিষেবা") ব্যবহার করেন তখন Sellkon কীভাবে আপনার ব্যক্তিগত তথ্য সংগ্রহ করে, ব্যবহার করে, শেয়ার করে এবং সুরক্ষিত করে।
            </div>
            <div className='txt'>
                আমাদের পরিষেবাগুলি অ্যাক্সেস বা ব্যবহার করে, আপনি এই গোপনীয়তা নীতির শর্তাবলীতে সম্মত হন৷ আপনি যদি এই গোপনীয়তা নীতির শর্তাবলীর সাথে একমত না হন তবে দয়া করে আমাদের পরিষেবাগুলি ব্যবহার করবেন না৷
            </div>
            <div className='title'>
                ১. যে তথ্য আমরা সংগ্রহ করি
            </div>
            <div className='txt'>

            </div>
            <div className='txt'>
                <ul>
                    <li>ব্যক্তিগত তথ্য: এর মধ্যে এমন তথ্য রয়েছে যা আপনাকে সনাক্ত করতে ব্যবহার করা যেতে পারে, যেমন আপনার নাম, ইমেল ঠিকানা, ডাক ঠিকানা, ফোন নম্বর এবং অর্থপ্রদানের তথ্য।</li>
                    <li>অ-ব্যক্তিগত তথ্য: এর মধ্যে এমন তথ্য রয়েছে যা আপনাকে ব্যক্তিগতভাবে সনাক্ত করতে ব্যবহার করা যাবে না, যেমন আপনার আইপি এড্রেস, ব্রাউজারের ধরন, ডিভাইসের ধরন এবং ব্যবহারের ডেটা।</li>
                </ul>
            </div>
            <div className='title'>২. আমরা কিভাবে তথ্য সংগ্রহ করি</div>
            <div className='txt'>আমরা আপনার কাছ থেকে বিভিন্ন উপায়ে তথ্য সংগ্রহ করতে পারি, যার মধ্যে রয়েছে:</div>
            <div className='txt'>
                <ul>
                    <li>আপনি যখন Sellkon.com এ একটি অ্যাকাউন্ট নিবন্ধন করেন</li>
                    <li>যখন আপনি একটি ক্রয় বা লেনদেন করেন</li>
                    <li>আপনি যখন আমাদের ওয়েবসাইটের সাথে ইন্টারঅ্যাক্ট করেন, যেমন ব্রাউজিং বা পণ্য অনুসন্ধান করে</li>
                    <li>আপনি যখন আমাদের গ্রাহক সহায়তা দলের সাথে যোগাযোগ করেন</li>
                </ul>
            </div>
            <div className='title'>৩. আমরা কিভাবে তথ্য ব্যবহার করি</div>
            <div className='txt'>আমরা যে তথ্য সংগ্রহ করি তা বিভিন্ন উদ্দেশ্যে ব্যবহার করতে পারি, যার মধ্যে রয়েছে:</div>
            <div className='txt'>
                <ul>
                    <li>আমাদের পরিষেবাগুলি প্রদান এবং উন্নত করতে</li>
                    <li>লেনদেন প্রক্রিয়া এবং অর্ডার সম্পন্ন করতে</li>
                    <li>আপনার অ্যাকাউন্ট এবং কেনাকাটা সম্পর্কে আপনার সাথে যোগাযোগ করতে</li>
                    <li>আপনাকে বিপণন যোগাযোগ এবং প্রচারমূলক অফার পাঠাতে</li>
                    <li>আপনার অনুসন্ধানের উত্তর দিতে এবং গ্রাহক সহায়তা প্রদান করতে</li>
                    <li>জালিয়াতি এবং অন্যান্য বেআইনি কার্যকলাপ সনাক্তকরণ, প্রতিরোধ এবং তদন্ত করা</li>
                    <li>আইনি বাধ্যবাধকতা মেনে চলতে</li>
                </ul>
            </div>
            <div className='title'>৪. আমরা কিভাবে তথ্য শেয়ার করি</div>
            <div className='txt'>আমরা বিভিন্ন উদ্দেশ্যে তৃতীয় পক্ষের সাথে আপনার তথ্য শেয়ার করতে পারি, যার মধ্যে রয়েছে:</div>
            <div className='txt'>
                <ul>
                    <li>পরিষেবা প্রদানকারীদের সাথে যারা আমাদের ওয়েবসাইট পরিচালনা করতে এবং আমাদের পরিষেবা প্রদান করতে সহায়তা করে</li>
                    <li>লেনদেন প্রক্রিয়া করার জন্য পেমেন্ট প্রসেসরের সাথে</li>
                    <li>অর্ডার পূরণ করতে শিপিং এবং লজিস্টিক প্রদানকারীদের সাথে</li>
                    <li>আপনাকে প্রচারমূলক অফার পাঠাতে বিপণন অংশীদারদের সাথে</li>
                    <li>আইন প্রয়োগকারী কর্তৃপক্ষ এবং সরকারী সংস্থাগুলির সাথে আইনি বাধ্যবাধকতা মেনে চলতে এবং আমাদের অধিকার রক্ষা করতে</li>
                </ul>
            </div>
            <div className='txt'>আমরা আপনার সুস্পষ্ট সম্মতি ছাড়া তৃতীয় পক্ষের কাছে তাদের বিপণনের উদ্দেশ্যে আপনার ব্যক্তিগত তথ্য বিক্রি বা ভাড়া দিই না।</div>
            <div className='title'>৫. আপনার সম্মতি</div>
            <div className='txt'>আপনার তথ্য সংগ্রহ, ব্যবহার এবং শেয়ার করে নেওয়ার বিষয়ে আপনার সিদ্ধান্ত নেবার স্বাধীনতা আছে:</div>
            <div className='txt'>
                <ul>
                    <li>আপনি Sellkon.com-এ আপনার অ্যাকাউন্ট সেটিংস অ্যাক্সেস করে আপনার ব্যক্তিগত তথ্য আপডেট বা সংশোধন করতে পারেন।</li>
                    <li>যোগাযোগে প্রদত্ত নির্দেশাবলী অনুসরণ করে আপনি আমাদের কাছ থেকে বিপণন যোগাযোগগুলি গ্রহণ করা থেকে বিরত থাকতে পারেন৷</li>
                    <li>আপনি আপনার ব্রাউজার সেটিংসে কুকিজ এবং অন্যান্য ট্র্যাকিং প্রযুক্তি নিষ্ক্রিয় করতে পারেন, যদিও এটি আমাদের ওয়েবসাইটের কার্যকারিতাকে প্রভাবিত করতে পারে।</li>
                </ul>
            </div>
            <div className='title'>৬. নিরাপত্তা</div>
            <div className='txt'>অননুমোদিত অ্যাক্সেস, ব্যবহার বা প্রকাশ থেকে আপনার ব্যক্তিগত তথ্য রক্ষা করার জন্য আমরা যুক্তিসঙ্গত ব্যবস্থা গ্রহণ করি। যাইহোক, ইন্টারনেট বা ইলেকট্রনিক স্টোরেজের মাধ্যমে ট্রান্সমিশনের কোনো পদ্ধতিই ১০০% নিরাপদ নয়, তাই আমরা পরম নিরাপত্তার নিশ্চয়তা দিতে পারি না।</div>
            <div className='title'>৭. শিশুদের গোপনীয়তা</div>
            <div className='txt'>আমাদের পরিষেবাগুলি ১৩ বছরের কম বয়সী শিশুদের জন্য নয়, এবং আমরা জেনেশুনে ১৩ বছরের কম বয়সী শিশুদের কাছ থেকে ব্যক্তিগত তথ্য সংগ্রহ করি না৷ আপনি যদি একজন অভিভাবক হন এবং বিশ্বাস করেন যে আপনার সন্তান আমাদের ব্যক্তিগত তথ্য প্রদান করেছে, অনুগ্রহ করে আমাদের সাথে যোগাযোগ করুন যেন আমরা তথ্য মুছে দিতে পারি।</div>
            <div className='title'>৮. এই গোপনীয়তা নীতিতে পরিবর্তন</div>
            <div className='txt'>আমাদের চর্চা বা প্রযোজ্য আইনের পরিবর্তনগুলি প্রতিফলিত করতে আমরা সময়ে সময়ে এই গোপনীয়তা নীতি আপডেট করতে পারি। Sellkon.com-এ আপডেট করা গোপনীয়তা নীতি পোস্ট করার মাধ্যমে আমরা আপনাকে কোনো পরিবর্তন সম্পর্কে অবহিত করব। সংশোধিত গোপনীয়তা নীতির কার্যকর তারিখের পরে আমাদের পরিষেবাগুলি আপনার অবিরত ব্যবহার পরিবর্তনগুলিকে আপনার গ্রহণযোগ্যতা অনুমোদন করে।</div>
            <div className='title'>৯. যোগাযোগ</div>
            <div className='txt'>ই গোপনীয়তা নীতি বা আমাদের গোপনীয়তা চর্চা সম্পর্কে আপনার কোন প্রশ্ন বা উদ্বেগ থাকলে, অনুগ্রহ করে আমাদের সাথে
                <span className='support_email ml5'>
                    <a className='cp' href>support@sellkon.com</a>
                </span>
                <span className='ml5'>এ যোগাযোগ করুন।</span>
            </div>
            <div className='txt'>Sellkon.com ব্যবহার করে, আপনি স্বীকার করছেন যে আপনি এই গোপনীয়তা নীতি পড়েছেন, বুঝেছেন এবং সম্মত হয়েছেন।</div>
        </>
    )
}

export default PrivacyPolicyBangla