import React from 'react'

const ReturnRefundBangla = () => {
    return (
        <>
            <div className='title tac'>রিটার্ন এবং রিফান্ড নীতি</div>
            <div className='txt'>
                Sellkon.com এ কেনাকাটা করার জন্য আপনাকে ধন্যবাদ। আমরা আপনাকে একটি সন্তোষজনক কেনাকাটার অভিজ্ঞতা প্রদান করতে প্রতিশ্রুতিবদ্ধ। আপনি যদি আপনার ক্রয়ের সাথে সম্পূর্ণরূপে সন্তুষ্ট না হন, আমরা সাহায্য করতে এখানে আছি।
            </div>
            <div className='title'>১. রিটার্নের যোগ্যতা:</div>
            <div className='txt'>
                <ul>
                    <li>আপনি একটি পণ্য প্রাপ্তির ৭ দিনের মধ্যে ফেরত দিতে পারেন, যদি এটি নিম্নলিখিত মানদণ্ড পূরণ করে:
                        <ul>
                            <li>পণ্যটি অব্যবহৃত এবং তার আসল অবস্থায় রয়েছে।</li>
                            <li>পণ্যটি তার আসল প্যাকেজিংয়ে রয়েছে, সমস্ত ট্যাগ এবং লেবেল সংযুক্ত রয়েছে৷</li>
                            <li>রিটার্নের বৈধ কারণ আছে, যেমন পণ্যের ত্রুটি বা ভুল পণ্য গ্রহণ করা।</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className='title'>২. অফেরতযোগ্য পণ্য:</div>
            <div className='txt'>
                <ul>
                    <li>স্বাস্থ্যবিধির কারণে বা অন্যান্য বিবেচনার কারণে কিছু পণ্য ফেরত পাওয়ার যোগ্য নয়। এর মধ্যে রয়েছে:
                        <ul>
                            <li>পারসোনাল কেয়ার আইটেম (যেমনঃ প্রসাধনী, অন্তর্বাস)।</li>
                            <li>পচনশীল পণ্য (যেমন, খাদ্য, ফুল)।</li>
                            <li>কাস্টমাইজড বা ব্যক্তিগতকৃত পণ্য।</li>
                            <li>পণ্যগুলি তাদের পণ্য পৃষ্ঠাগুলিতে অফেরতযোগ্য হিসাবে চিহ্নিত।</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className='title'>৩. রিটার্ন প্রক্রিয়া:</div>
            <div className='txt'>
                <ul>
                    <li>একটি রিটার্ন শুরু করতে, অনুগ্রহ করে আপনার Sellkon.com অ্যাকাউন্টে লগ ইন করুন এবং আপনার অর্ডার হিস্ট্রি পেজ এ যান৷</li>
                    <li>আপনি যে পণ্য(গুলি) ফেরত দিতে চান তা নির্বাচন করুন এবং ফেরত দেওয়ার কারণ লিখুন৷</li>
                    <li>আমাদের গ্রাহক পরিষেবা দল আপনার অনুরোধ পর্যালোচনা করবে এবং অনুমোদিত হলে আপনাকে একটি ফেরত অনুমোদন(RA) নম্বর প্রদান করবে।</li>
                    <li>পণ্যটি নিরাপদে প্যাক করুন এবং সমস্ত মূল প্যাকেজিং, ট্যাগ এবং আনুষাঙ্গিক অন্তর্ভুক্ত করুন।</li>
                    <li>প্যাকেজে স্পষ্টভাবে RA নম্বর লিখুন।</li>
                    <li>রিটার্ন প্যাকেজটি আমাদের নির্ধারিত ঠিকানায় পাঠান।</li>
                </ul>
            </div>
            <div className='title'>৪. রিফান্ড বা পণ্য বিনিময়:</div>
            <div className='txt'>
                <ul>
                    <li>একবার আমরা ফিরে আসা পণ্যটি গ্রহণ এবং যাচাই করার পরে, আমরা আপনাকে আপনার রিটার্ন এর অনুমোদন বা প্রত্যাখ্যান সম্পর্কে অবহিত করব।</li>
                    <li>অনুমোদিত হলে, আপনি অর্থ ফেরত পেতে বা সমান মূল্যের অন্য পণ্য বিনিময় করতে পারেন।</li>
                    <li>ফেরত দেওয়ার ৫ কার্যদিবসের মধ্যে মূল অর্থপ্রদানের পদ্ধতিতে অর্থ ফেরত দেওয়া হবে। অনুগ্রহ করে মনে রাখবেন যে আপনার ব্যাঙ্ক বা পেমেন্ট প্রোভাইডার এর উপর নির্ভর করে আপনার অ্যাকাউন্টে রিফান্ড প্রতিফলিত হতে অতিরিক্ত সময় লাগতে পারে।</li>
                </ul>
            </div>
            <div className='title'>৫. রিটার্ন শিপিং:</div>
            <div className='txt'>
                <ul>
                    <li>গ্রাহকরা রিটার্ন শিপিংয়ের খরচের জন্য দায়ী যদি না রিটার্নটি আমাদের ত্রুটির কারণে হয় (যেমন, ভুল পণ্য পাঠানো, ত্রুটিপূর্ণ পণ্য)।</li>
                </ul>
            </div>
            <div className='title'>৬. ক্ষতিগ্রস্থ বা ত্রুটিপূর্ণ পণ্য:</div>
            <div className='txt'>
                <ul>
                    <li>আপনি যদি একটি ত্রুটিপূর্ণ পণ্য পান, সহায়তার জন্য অবিলম্বে আমাদের সাথে যোগাযোগ করুন।</li>
                    <li>আমাদের মূল্যায়নের জন্য ত্রুটিপূর্ণ পণ্যটি এবং সেইসাথে প্যাকেজিং এর পরিষ্কার ফটোগ্রাফ প্রদান করুন।</li>
                    <li>আমরা প্রয়োজনে রিটার্ন শিপিংয়ের খরচসহ উপযুক্ত একটি বদলি বা রিফান্ডের ব্যবস্থা করব।</li>
                </ul>
            </div>
            <div className='title'>৭. বাতিলকরণ নীতি:</div>
            <div className='txt'>
                <ul>
                    <li>Sellkon.com এর একজন এডমিন আপনার অর্ডারটি “কনফার্ম” করার আগে আপনি এটি বাতিল করতে পারবেন।</li>
                    <li>একটি অর্ডার “কনফার্ম” হওয়ার পরে, যদি আপনি এটি বাতিল করতে চান, আপনার পণ্যটি একজন ডেলিভারি ম্যান "পিক" করার আগে আপনাকে আমাদের সাথে যোগাযোগ করতে হবে। একবার আপনার পণ্য "পিক" করা হলে, আপনি এটি বাতিল করতে পারবেন না। এই ক্ষেত্রে, আপনি আমাদের স্বাভাবিক রিটার্ন নীতি অনুসরণ করে রিটার্ন করতে পারেন।</li>
                </ul>
            </div>
            <div className='title'>৮. যোগাযোগ:</div>
            <div className='txt'>
                <ul>
                    <li>আমাদের রিটার্ন এবং রিফান্ড নীতি সম্পর্কে আপনার কোন প্রশ্ন থাকলে, অনুগ্রহ করে <span className='support_email ml5 mr5'>
                        <a className='cp' href>support@sellkon.com</a>
                    </span> এ আমাদের গ্রাহক পরিষেবা দলের সাথে যোগাযোগ করতে দ্বিধা করবেন না।
                    </li>
                </ul>
            </div>
            <div className='txt'>
                <span className='note'>দ্রষ্টব্য:</span> এই রিটার্ন এবং রিফান্ড নীতি পূর্বে বিজ্ঞপ্তি ছাড়াই আমাদের দ্বারা পরিবর্তনযোগ্য। সবচেয়ে সাম্প্রতিক তথ্যের জন্য অনুগ্রহ করে আমাদের ওয়েবসাইটে সর্বশেষ সংস্করণ দেখুন।
            </div>

        </>
    )
}

export default ReturnRefundBangla