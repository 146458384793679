import React from 'react'

const TermsBangla = () => {
    return (
        <>
            <div className='title tac'>Sellkon.com এর নিয়ম ও শর্তাবলী</div>
            <div className='txt'>
                Sellkon.com এ স্বাগতম। এই নিয়ম ও শর্তাবলী আমাদের ওয়েবসাইট এবং এতে প্রদত্ত পরিষেবার ব্যবহার নিয়ন্ত্রণ করে। ওয়েবসাইট অ্যাক্সেস বা ব্যবহার করে, আপনি এই শর্তাবলী দ্বারা আবদ্ধ হতে সম্মত হন. আপনি যদি এই শর্তাবলীর কোন অংশের সাথে একমত না হন তবে আপনি ওয়েবসাইটটি ব্যবহার করতে পারবেন না।
            </div>
            <div className='title'>১. শর্তাবলী গ্রহণ</div>
            <div className='txt'>ওয়েবসাইট ব্যবহার করে, আপনি এই শর্তাদি এবং এখানে উল্লেখিত যেকোনো প্রযোজ্য নীতি ও নির্দেশিকা মেনে চলতে সম্মত হন।</div>

            <div className='title'>২. ওয়েবসাইট ব্যবহার</div>
            <div className='txt'>
                আপনার বয়স অবশ্যই কমপক্ষে ১৮ বছর হতে হবে বা পিতামাতা বা আইনী অভিভাবকের তত্ত্বাবধানে সাইটটি অ্যাক্সেস করতে হবে ৷ ওয়েবসাইট ব্যবহার করার জন্য আপনার কাছে চুক্তিতে প্রবেশ করার আইনি ক্ষমতা থাকতে হবে। আপনি একটি অ্যাকাউন্ট তৈরি করার সময় সঠিক এবং সম্পূর্ণ তথ্য প্রদান করতে এবং আপনার অ্যাকাউন্টের তথ্য হালনাগাদ রাখতে সম্মত হন। আপনার ইউজার আইডি, পাসওয়ার্ড, অ্যাকাউন্টের বিবরণ এবং অন্যান্য ব্যক্তিগত তথ্যের গোপনীয়তা বজায় রাখার জন্য আপনি দায়ী। আপনি যদি মনে করেন যে আপনার পাসওয়ার্ড অন্য কারো কাছে প্রকাশ হয়েছে তাহলে আপনাকে অবিলম্বে আমাদের জানাতে হবে।
                <p>আমরা আপনাকে সাইট বা এর কোনো অংশ ডাউনলোড(পৃষ্ঠার ক্যাশে ব্যতীত) বা পরিবর্তন করার অনুমতি দিই না। এই সাইট বা এর বিষয়বস্তু পুনঃবিক্রয় বা বাণিজ্যিক ব্যবহারের জন্য অনুমোদিত নয়। ওয়েব স্ক্র্যাপিং, ডেটা মাইনিং বা অন্য কোনও ডেটা সংগ্রহের পদ্ধতি ব্যবহারও নিষিদ্ধ৷</p>
                <p>আপনি সাইট বা অন্য কোনো সংশ্লিষ্ট পক্ষের ক্ষতি হতে পারে এমন কার্যকলাপে নিযুক্ত হওয়া নিষেধ।</p>
            </div>
            <div className='title'>৩. পণ্য তালিকা, মূল্য নির্ধারণ, এবং আদেশ</div>
            <div className='txt'>
                আমরা পণ্যের বর্ণনা, দাম এবং প্রাপ্যতা সহ সঠিক পণ্য তালিকা প্রদান করার চেষ্টা করি। যাইহোক, আমরা এই তথ্যের নির্ভুলতার গ্যারান্টি দিই না। ওয়েবসাইটে একটি অর্ডার প্রদত্ত পণ্যের মূল্য এবং শর্তাবলীর অধীনে পণ্য কেনার একটি অফার গঠন করে।
                <p>সমস্ত মূল্য বাংলাদেশী টাকায় (বিডিটি) তালিকাভুক্ত এবং ভ্যাটযুক্ত। আপনার শপিং কার্টের আইটেমগুলি সর্বদা আইটেমের পণ্যের বিশদ পৃষ্ঠায় প্রদর্শিত সর্বাধিক আপ-টু-ডেট মূল্য প্রতিফলিত করবে। অনুগ্রহ করে সচেতন থাকুন যে এই মূল্যটি আইটেমটির জন্য দেখানো মূল্য থেকে ভিন্ন হতে পারে যখন আপনি এটিকে আপনার কার্টে প্রাথমিকভাবে যোগ করেছিলেন। আপনার কার্টে একটি আইটেম যোগ করা সেই সময়ে দেখানো মূল্য লক করে না। উপরন্তু, আপনি আপনার কার্টে যোগ করার মুহুর্ত এবং ক্রয়ের সময়ের মধ্যে একটি আইটেমের দামের পার্থক্য হতে পারে।</p>
                <p>আপনি সাইট থেকে পণ্য ক্রয়ের সাথে সম্পর্কিত সমস্ত ফি/খরচ/চার্জ প্রদানের জন্য দায়ী থাকবেন এবং আপনি প্রচলিত আইন অনুযায়ী যে কোনো এবং সমস্ত প্রযোজ্য কর বহন করতে সম্মত হন।</p>
            </div>
            <div className='title'>৪. পেমেন্ট</div>
            <div className='txt'>
                ওয়েবসাইটের মাধ্যমে অর্ডারের জন্য অর্থপ্রদান অবশ্যই চেকআউটে নির্দিষ্ট করা স্বীকৃত অর্থপ্রদানের পদ্ধতিগুলির একটি ব্যবহার করে করা উচিত। অর্ডার দেওয়ার মাধ্যমে, আপনি অর্ডারের মোট পরিমাণের জন্য প্রযোজ্য অর্থপ্রদানের পদ্ধতি চার্জ করার জন্য আমাদের অনুমোদন করেন।
            </div>
            <div className='title'>৫. শিপিং এবং ডেলিভারি</div>
            <div className='txt'>
                আমরা প্রদত্ত আনুমানিক বিতরণ সময়ের মধ্যে অর্ডার সরবরাহ করার জন্য সর্বচ্চো প্রচেষ্টা করব। যাইহোক, আমাদের নিয়ন্ত্রণের বাইরে বিলম্বের জন্য আমরা দায়ী নই। শিপিং ফি এবং ডেলিভারি অপশন চেকআউট এ নির্দিষ্ট করা হয়.
            </div>
            <div className='title'>৬. সাবমিশন</div>
            <div className='txt'>
                আপনি যা কিছু সাইটে বা আমাদেরকে প্রদান করেন, যার মধ্যে অনুসন্ধান, মূল্যায়ন, পর্যালোচনা, মন্তব্য এবং সুপারিশ (সমষ্টিগতভাবে "সাবমিশন" হিসাবে উল্লেখ করা হয়) সহ কিন্তু সীমাবদ্ধ নয়, আমাদের একচেটিয়া সম্পত্তি হয়ে যাবে। উপরন্তু, আপনি যখন সাইটে মন্তব্য বা পর্যালোচনা পোস্ট করেন, আপনি আমাদের এই ধরনের পর্যালোচনা, মন্তব্য বা অন্যান্য বিষয়বস্তুর সাথে আপনার দেওয়া নাম ব্যবহার করার অধিকার প্রদান করেন। আপনি নকল ইমেল ঠিকানা ব্যবহার না করতে এবং অন্য ব্যক্তির ছদ্মবেশ ধারণ না করতে সম্মত হন, বা অন্যথায় আমাদের বা তৃতীয় পক্ষকে প্রতারিত করবেন না যেকোন জমার উৎস সম্পর্কে। যদিও আমরা কোনো জমা অপসারণ বা সংশোধন করার অধিকার সংরক্ষণ করি, আমরা নোটিশ ছাড়াই বা এই বিষয়ে আমাদের জন্য প্রযোজ্য কোনো আইনি প্রক্রিয়া অনুসরণ না করে তা করতে বাধ্য নই।
            </div>
            <div className='title'>৭. রিটার্ন এবং রিফান্ড</div>
            <div className='txt'>
                আমাদের রিটার্ন এবং রিফান্ড নীতি আমাদের রিটার্ন এবং রিফান্ড পৃষ্ঠায় বর্ণিত আছে। একটি অর্ডার করার আগে সাবধানে এই নীতি পর্যালোচনা করুন.
            </div>
            <div className='title'>৮. বুদ্ধিবৃত্তিক সম্পত্তি</div>
            <div className='txt'>পণ্যের ছবি, লোগো এবং ট্রেডমার্ক সহ ওয়েবসাইটের সমস্ত সামগ্রী Sellkon.com বা এর লাইসেন্সকারীদের সম্পত্তি এবং কপিরাইট এবং অন্যান্য মেধা সম্পত্তি আইন দ্বারা সুরক্ষিত। আপনি আমাদের পূর্ব লিখিত সম্মতি ছাড়া ওয়েবসাইট থেকে কোনো সামগ্রী ব্যবহার, পুনরুত্পাদন বা বিতরণ করতে পারবেন না।</div>
            <div className='title'>৯. গোপনীয়তা নীতি</div>
            <div className='txt'>আমাদের গোপনীয়তা নীতি আপনার ব্যক্তিগত তথ্য সংগ্রহ, ব্যবহার এবং প্রকাশ নিয়ন্ত্রণ করে। ওয়েবসাইট ব্যবহার করে, আপনি আমাদের গোপনীয়তা নীতির শর্তাবলীতে সম্মত হন, যা আমাদের গোপনীয়তা নীতি পৃষ্ঠায় পাওয়া যাবে।</div>
            <div className='title'>১০. দায়বদ্ধতার সীমাবদ্ধতা </div>
            <div className='txt'>বিক্রেতাদের কোনো কর্ম বা নিষ্ক্রিয়তা বা পণ্যের বিক্রেতা বা নির্মাতাদের শর্ত, উপস্থাপনা, বা ওয়ারেন্টি লঙ্ঘনের জন্য আমরা দায়ী থাকব না। সমস্ত বাণিজ্যিক/চুক্তিমূলক শর্তাবলী শুধুমাত্র আপনার এবং বিক্রেতাদের মধ্যে সম্মত হয়। বাণিজ্যিক/চুক্তিমূলক শর্তাবলীর মধ্যে রয়েছে মূল্য, শিপিং খরচ, অর্থপ্রদানের পদ্ধতি, অর্থপ্রদানের শর্তাবলী, তারিখ, সময়কাল এবং ডেলিভারির পদ্ধতি, পণ্য ও পরিষেবার সাথে সম্পর্কিত ওয়্যারেন্টি এবং পণ্য ও পরিষেবা সম্পর্কিত বিক্রয়োত্তর পরিষেবা।৷ আমরা আপনার এবং বিক্রেতাদের মধ্যে কোনো বিরোধ সমাধান করতে বাধ্য নই। আমরা পেমেন্ট প্রদানকারী, কিস্তি অফার, ওয়ারেন্টি পরিষেবা সহ অন্যদের মধ্যে সীমাবদ্ধ নয় এমন কোনো পরিষেবা প্রদানকারীর কোনো কর্ম বা নিষ্ক্রিয়তার জন্য দায়ী থাকব না।</div>
            <div className='title'>১১. আইন এবং এখতিয়ার</div>
            <div className='txt'>এই শর্তাবলী বাংলাদেশের আইন অনুসারে পরিচালিত হবে এবং বোঝানো হবে। এই শর্তাবলী থেকে উদ্ভূত বা এর সাথে সম্পর্কিত যে কোন বিরোধ বাংলাদেশের আদালতের একচেটিয়া এখতিয়ারের অধীন হবে।</div>
            <div className='title'>১২. শর্তাবলী পরিবর্তন</div>
            <div className='txt'>আমরা পূর্ব বিজ্ঞপ্তি ছাড়াই যে কোন সময় এই শর্তাবলী পরিবর্তন করার অধিকার সংরক্ষণ করি। পরিবর্তনগুলি ওয়েবসাইটে পোস্ট করার সাথে সাথেই কার্যকর হবে৷ এই ধরনের যেকোনো পরিবর্তনের পর আপনার ওয়েবসাইটটির ব্যবহার অব্যাহত রাখা আপনার পরিবর্তিত শর্তাবলীর স্বীকৃতি প্রদান করে।</div>
            <div className='title'>১৩. যোগাযোগ</div>
            <div className='txt'>এই শর্তাবলী সম্পর্কে আপনার কোন প্রশ্ন থাকলে, অনুগ্রহ করে
                <span className='support_email ml5 mr5'>
                    <a className='cp' href>support@sellkon.com</a>
                </span>
                এ আমাদের সাথে যোগাযোগ করুন।
            </div>

        </>
    )
}

export default TermsBangla