import React from 'react'

const PrivacyPolicyEnglish = () => {
    return (
        <>
            <div className='title tac'>Privacy Policy for Sellkon.com</div>
            <div className='txt'>
                Last Updated: May 5, 2024
            </div>
            <div className='txt'>
                Sellkon.com ("Sellkon," "we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy describes how Sellkon collects, uses, shares, and protects your personal information when you use our website, Sellkon.com, and any related services (collectively, the "Services").
            </div>
            <div className='txt'>
                By accessing or using our Services, you agree to the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our Services.
            </div>
            <div className='title'>
                1. Information We Collect
            </div>
            <div className='txt'>
                We may collect various types of information when you use our Services, including:
            </div>
            <div className='txt'>
                <ul>
                    <li>Personal Information: This includes information that can be used to identify you, such as your name, email address, postal address, phone number, and payment information</li>
                    <li>Non-personal Information: This includes information that cannot be used to identify you personally, such as your IP address, browser type, device type, and usage data.</li>
                </ul>
            </div>
            <div className='title'>2. How We Collect Information</div>
            <div className='txt'>We may collect information from you in various ways, including:</div>
            <div className='txt'>
                <ul>
                    <li>When you register an account on Sellkon.com</li>
                    <li>When you make a purchase or transaction</li>
                    <li>When you interact with our website, such as by browsing or searching for products</li>
                    <li>When you contact our customer support team</li>
                </ul>
            </div>
            <div className='title'>3. How We Use Information</div>
            <div className='txt'>We may use the information we collect for various purposes, including:</div>
            <div className='txt'>
                <ul>
                    <li>To provide and improve our Services</li>
                    <li>To process transactions and fulfill orders</li>
                    <li>To communicate with you about your account and purchases</li>
                    <li>To send you marketing communications and promotional offers</li>
                    <li>To respond to your inquiries and provide customer support</li>
                    <li>To detect, prevent, and investigate fraud and other illegal activities</li>
                    <li>To comply with legal obligations</li>
                </ul>
            </div>
            <div className='title'>4. How We Share Information</div>
            <div className='txt'>We may share your information with third parties for various purposes, including:</div>
            <div className='txt'>
                <ul>
                    <li>With service providers who help us operate our website and provide our Services</li>
                    <li>With payment processors to process transactions</li>
                    <li>With shipping and logistics providers to fulfill orders</li>
                    <li>With marketing partners to send you promotional offers</li>
                    <li>With law enforcement authorities and government agencies to comply with legal obligations and protect our rights</li>
                </ul>
            </div>
            <div className='txt'>We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent.</div>
            <div className='title'>5. Your Choices</div>
            <div className='txt'>You have certain choices regarding the collection, use, and sharing of your information:</div>
            <div className='txt'>
                <ul>
                    <li>You can update or correct your personal information by accessing your account settings on Sellkon.com.</li>
                    <li>You can opt out of receiving marketing communications from us by following the instructions provided in the communication.</li>
                    <li>You can disable cookies and other tracking technologies in your browser settings, although this may affect the functionality of our website.</li>
                </ul>
            </div>
            <div className='title'>6. Security</div>
            <div className='txt'>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.</div>
            <div className='title'>7. Children's Privacy</div>
            <div className='txt'>Our Services are not intended for children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.</div>
            <div className='title'>8. Changes to this Privacy Policy</div>
            <div className='txt'>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated Privacy Policy on Sellkon.com. Your continued use of our Services after the effective date of the revised Privacy Policy constitutes your acceptance of the changes.</div>
            <div className='title'>9. Contact Us</div>
            <div className='txt'>If you have any questions or concerns about these Terms, please contact us at
                <div className='support_email'>
                    <a className='cp' href>support@sellkon.com</a>
                </div>
            </div>
            <div className='txt'>By using Sellkon.com, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.</div>
        </>
    )
}

export default PrivacyPolicyEnglish