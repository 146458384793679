import React from 'react'

const ReturnRefundEnglish = () => {
    return (
        <>
            <div className='title tac'>Return and Refund Policy</div>
            <div className='txt'>
                Thank you for shopping at Sellkon.com. We are committed to providing you with a satisfying shopping experience. If you are not entirely satisfied with your purchase, we're here to help.
            </div>
            <div className='title'>1. Eligibility for Returns:</div>
            <div className='txt'>
                <ul>
                    <li>You may return a product within 7 days of receiving it, provided it meets the following criteria:
                        <ul>
                            <li>The item is unused, unworn, and in its original condition.</li>
                            <li>The product is in its original packaging, with all tags and labels attached.</li>
                            <li>The return is initiated with a valid reason, such as a defect in the product or receiving the wrong item.</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className='title'>2. Non-Returnable Items:</div>
            <div className='txt'>
                <ul>
                    <li>Certain products are not eligible for return due to hygiene reasons or other considerations. These include:
                        <ul>
                            <li>Personal care items (e.g., cosmetics, undergarments).</li>
                            <li>Perishable goods (e.g., food, flowers).</li>
                            <li>Customized or personalized items.</li>
                            <li>Items marked as non-returnable on their product pages.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className='title'>3. Return Process:</div>
            <div className='txt'>
                <ul>
                    <li>To initiate a return, please log in to your Sellkon.com account and navigate to your order history.</li>
                    <li>Select the item(s) you wish to return and provide a reason for the return.</li>
                    <li>Our customer service team will review your request and provide you with a return authorization (RA) number if approved.</li>
                    <li>Pack the item securely and include all original packaging, tags, and accessories.</li>
                    <li>Clearly mark the RA number on the package.</li>
                    <li>Ship the return package to our designated return address.</li>
                </ul>
            </div>
            <div className='title'>4. Refund or Exchange:</div>
            <div className='txt'>
                <ul>
                    <li>Once we receive and inspect the returned item, we will notify you of the approval or rejection of your return.</li>
                    <li>If approved, you may choose to receive a refund or exchange the item for another product of equal value.</li>
                    <li>Refunds will be issued to the original payment method within 5 business days of return. Please note that it may take additional time for the refund to reflect in your account, depending on your bank or payment provider.</li>
                </ul>
            </div>
            <div className='title'>5. Return Shipping:</div>
            <div className='txt'>
                <ul>
                    <li>Customers are responsible for the cost of return shipping unless the return is due to our error (e.g., wrong item shipped, defective product).</li>
                </ul>
            </div>
            <div className='title'>6. Damaged or Defective Items:</div>
            <div className='txt'>
                <ul>
                    <li>If you receive a damaged or defective item, please contact us immediately for assistance.</li>
                    <li>Provide clear photographs of the damaged or defective item, as well as the packaging, for our assessment.</li>
                    <li>We will arrange for a replacement or refund as appropriate, including covering the cost of return shipping if necessary.</li>
                </ul>
            </div>
            <div className='title'>7. Cancellation Policy:</div>
            <div className='txt'>
                <ul>
                    <li>Orders can be canceled before an admin of Sellkon.com “Confirm” it.</li>
                    <li>After an order is confirmed, and you want to cancel it, you have to contact us before your product is “Picked” by a delivery man. Once your product is picked, you can not cancel it. In such cases, you may initiate a return following our standard return policy.</li>
                </ul>
            </div>
            <div className='title'>8. Contact Us:</div>
            <div className='txt'>
                <ul>
                    <li>If you have any questions or concerns about our return and refund policy, please don't hesitate to contact our customer service team at
                        <span className='support_email ml5 mr5'>
                            <a className='cp' href>support@sellkon.com</a>
                        </span></li>
                </ul>
            </div>
            <div className='txt'>
                <span className='note'>Note:</span> This return and refund policy is subject to change without prior notice. Please refer to the latest version on our website for the most up-to-date information.
            </div>

        </>
    )
}

export default ReturnRefundEnglish