import React from 'react'

const TermsEnglish = () => {
    return (
        <>
            <div className='title tac'>Terms and Conditions of Sellkon.com</div>
            <div className='txt'>
                Welcome to Sellkon.com. These terms and conditions ("Terms") govern your use of the Website and the services provided therein. By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not use the Website.
            </div>
            <div className='title'>1. Acceptance of Terms</div>
            <div className='txt'>By using the Website, you agree to abide by these Terms and any applicable policies and guidelines referenced herein.</div>

            <div className='title'>2. Use of the Website</div>
            <div className='txt'>
                You must be at least 18 years old or are accessing the Site under the supervision of a parent or legal guardian. You must have the legal capacity to enter into contracts to use the Website. You agree to provide accurate and complete information when creating an account and to keep your account information up to date. You are responsible for maintaining the confidentiality of your user identification, password, account details and related private information. You should inform us immediately if you believe that your password is disclosed to anyone else.
                <p>We do not allow you to download(excluding page caches) or modify the Site or any portion of it. It is not allowed to resale or commercial use of this site or its contents. Any use of web scraping, data mining or any other data-gathering tools is also prohibited.</p>
                <p>You must not engage in activities that may harm the Site or any other associated party.</p>
            </div>
            <div className='title'>3. Product Listings, Pricing, and Orders</div>
            <div className='txt'>
                We strive to provide accurate product listings, including descriptions, prices, and availability. However, we do not guarantee the accuracy of such information. Placing an order through the Website constitutes an offer to purchase the products at the prices and under the terms stated.
                <p>All prices are listed in Bangladeshi Taka (BDT) and are inclusive of VAT. The items of your Shopping Cart will always reflect the most up-to-date price displayed on the item's product detail page. Please be aware that this price might differ from the price shown for the item when you initially added it to your cart. Adding an item to your cart does not lock in the price shown at that time. Additionally, it is possible for an item's price to differ between the moment you add it to your cart and the time of purchase.</p>
                <p>You shall be responsible for payment of all fees/costs/charges associated with the purchase of products from the Site and you agree to bear any and all applicable taxes as per prevailing law.</p>
            </div>
            <div className='title'>4. Payment</div>
            <div className='txt'>
                Payment for orders placed through the Website must be made using one of the accepted payment methods specified at checkout. By placing an order, you authorize us to charge the applicable payment method for the total amount of the order.
            </div>
            <div className='title'>5. Shipping and Delivery</div>
            <div className='txt'>
                We will make reasonable efforts to deliver orders within the estimated delivery times provided. However, we are not responsible for delays outside of our control. Shipping fees and delivery options are specified at checkout.
            </div>
            <div className='title'>6. Submissions</div>
            <div className='txt'>
                Anything you submit to the Site or provide to us, including but not limited to inquiries, evaluations, remarks, reviews, comments, and recommendations (referred to collectively as "Submissions"), will become our exclusive property. Additionally, when you post comments or reviews on the Site, you grant us the right to use the name you provide in connection with such review, comment, or other content. You agree not to use a fake email address, impersonate another individual, or otherwise deceive us or third parties regarding the origin of any Submissions. While we reserve the right to remove or modify any Submissions, we are not obligated to do so without notice or without adhering to any legal procedures applicable to us in this matter.
            </div>
            <div className='title'>7. Returns and Refunds</div>
            <div className='txt'>
                Our return and refund policy is outlined on our Returns and Refunds page. Please review this policy carefully before making a purchase.
            </div>
            <div className='title'>8. Intellectual Property</div>
            <div className='txt'>All content on the Website, including product images, logos, and trademarks, is the property of Sellkon.com or its licensors and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from the Website without our prior written consent.</div>
            <div className='title'>9. Privacy Policy</div>
            <div className='txt'>Our privacy policy governs the collection, use, and disclosure of your personal information. By using the Website, you consent to the terms of our privacy policy, which can be found on our Privacy Policy page.</div>
            <div className='title'>10. Limitation of Liability</div>
            <div className='txt'>We shall not be liable for any actions or inactions of sellers or any breach of conditions, representations, or warranties by the sellers or manufacturers of the products. All commercial/contractual terms are offered by and agreed to between you and the sellers alone. The commercial/contractual terms include price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. We are not bound to resolve any dispute between you and the sellers. We shall not be responsible for any actions or inactions of any service provider including but not limited to payment providers, installment offerings, warranty services amongst others.</div>
            <div className='title'>11. Governing Law and Jurisdiction</div>
            <div className='txt'>These Terms shall be governed by and construed in accordance with the laws of Bangladesh. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of Bangladesh.</div>
            <div className='title'>12. Changes to Terms</div>
            <div className='txt'>We reserve the right to modify these Terms at any time without prior notice. Changes will be effective immediately upon posting to the Website. Your continued use of the Website after any such changes constitutes your acceptance of the modified Terms.</div>
            <div className='title'>13. Contact Us</div>
            <div className='txt'>If you have any questions or concerns about these Terms, please contact us at
                <div className='support_email'>
                    <a className='cp' href>support@sellkon.com</a>
                </div>
            </div>

        </>
    )
}

export default TermsEnglish